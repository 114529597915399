<div class="row">
    <div class="col-md-12 mt-2">
      <div>
        <div class="row">
          <div class="col-sm-12">
            <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="show">
          <div class="col-md-12">
              <div class="row mb-2">
                  <div>
                      <table class="table table-pointable table-bordered">
                          <thead>
                              <tr>
                                  <th (click)="toggleSort(0)">
                                      ID
                                      <app-sort-arrows [selectedColumn]="filter.column" [column]="0" [dir]="filter.dir">
                                      </app-sort-arrows>
                                  </th>
                                  <th>Status</th>
                                  <th (click)="toggleSort(2)">
                                      Name
                                      <app-sort-arrows [selectedColumn]="filter.column" [column]="2" [dir]="filter.dir">
                                      </app-sort-arrows>
                                  </th>
                                  <th>Type</th>
                                  <th width="15%">Action</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr *ngFor="let report of reportsAvailable" (click)="showReportAvaliable($event, report)">
                                  <td><a routerLink="{{isAdmin ? '/reports/' + report?.id : ''}}">{{report?.id}}</a></td>
                                  <td>{{report?.status}}</td>
                                  <td>{{report?.name}}</td>
                                  <td>
                                      <div *ngIf="report?.interactiveReport">Interactive</div>
                                      <div *ngIf="report?.dashboardReport">Dashboard</div>
                                      <div *ngIf="report?.outboundReport">Outbound</div>
                                      <div *ngIf="report?.forStaging">Integrations</div>
                                      <div
                                          *ngIf="!report?.interactiveReport && !report?.dashboardReport && !report?.outboundReport && !report?.forStaging">
                                          Unknown
                                      </div>
                                  </td>
                                  <td>
                                      <div class="float-left actions inline-table">
                                          <a *ngIf="contExteranlAppUrls(report) > 0 && report?.interactiveReport"
                                              class="btn btn-dark-blue btn-sm ml-1 float-left" title="Run Report"
                                              (click)="runReport(report)">
                                              <fa-icon [icon]="faRocket"></fa-icon>
                                              &nbsp;Launch
                                          </a>
                                          <a *ngIf="contExteranlAppUrls(report) === 0 && report?.interactiveReport"
                                              routerLink="reports/orb/{{report?.id}}" class="btn btn-dark-blue btn-sm ml-1 float-left"
                                              title="Run Report">
                                              <fa-icon [icon]="faRocket"></fa-icon>&nbsp;Launch
                                          </a>
                                          <a *ngIf="isAdmin" routerLink="/reports/{{report?.id}}"
                                              class="btn btn-dark-blue btn-sm ml-1 float-left" title="Manage">
                                              <fa-icon [icon]="faCogs"></fa-icon>
                                          </a>
                                      </div>
                                  </td>
                              </tr>
                              <tr app-is-there-more [numColumns]="5" [newLengthEvent]="newLengthEvent"></tr>
                          </tbody>
                      </table>
                  </div>
              </div>
              <app-add-more-data-to-list (onScroll)="getReportsAvaliable(true)" [lock]="lock"
                  [isLoading]="isLoading">
              </app-add-more-data-to-list>
          </div>
      </div>
  </div>
</div>
