import { SecurityService } from './../services/security/security.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppDataService } from '../app-data.service';
import { ConfigurationService } from '../services/configuration.service';
import { ApolloService } from '../services/apollo.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  username: string;
  password: string;
  emailError = false;
  passwordError = false;
  loginBanner = '';

  constructor(
    private globalData: AppDataService,
    private router: Router,
    private securityService: SecurityService,
    private configurationService: ConfigurationService,
    private apolloService: ApolloService
  ) {}

  submit(): void {
    if (!this.username || this.username.length < 1) {
      this.emailError = true;
      return;
    }

    if (!this.password || this.password.length < 1) {
      this.passwordError = true;
      return;
    }

    this.securityService.login(
      this.username,
      this.password,
      (res, isLoggedIn) => {
        if (isLoggedIn) {
          this.router.navigate(['/home']);
          this.apolloService.createClient();
        }
      },
      null
    );
  }

  getLoginBanner(): void {
    this.configurationService.getLoginBanner().subscribe((data) => {
      if (data.success && data.entity) {
        this.loginBanner = data.entity;
      }
    });
  }

  forgotPassword(): void {
    this.router.navigate(['forgot-password']);
  }

  ngOnInit(): void {
    if (localStorage.getItem('username') != null) {
      this.username = localStorage.getItem('username');
    }
    if (localStorage.getItem('token')) {
      this.router.navigate(['/home']);
    }

    // this.securityService.getIpClientAddress();
    this.getLoginBanner();
  }
}
