import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, AfterContentChecked } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-add-more-data-to-list',
  templateUrl: './add-more-data-to-list.component.html',
  styleUrls: ['./add-more-data-to-list.component.scss']
})
export class AddMoreDataToListComponent implements OnInit, OnDestroy, AfterContentChecked  {

  @Input() public lock: boolean;
  @Input() public isLoading: boolean;
  @Input() public searchApplied = true;
  @Input() public isInMiddle: boolean;
  marginTop = 0;

  // tslint:disable-next-line:no-output-on-prefix
  @Output() public onScroll: EventEmitter<any> = new EventEmitter();
  faSpinner = faSpinner;
  rotate = 0;
  constructor() {
    setInterval(() => {
      this.rotate += 90;
      if (this.rotate === 360) {
        this.rotate = 0;
      }
    }, 100);
  }

  ngAfterContentChecked(): void {
    if (this.searchApplied) {
      if (this.lock || this.isInMiddle) {
        this.marginTop = 0;
      } else {
        const bottom = document.getElementsByClassName('container')[0].getBoundingClientRect().bottom;
        const windowHeight = window.innerHeight;
        if (bottom - 30 - this.marginTop < windowHeight) {
          this.marginTop = windowHeight + 30 - (bottom - this.marginTop);
        } else if (bottom - this.marginTop - 30 > windowHeight) {
          this.marginTop = 0;
        }
      }
    }
  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollEvent, true);
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.scrollEvent, true);
  }

  scrollEvent = (event: Event): void => {
    setTimeout(() => {
      const target = event.target as Element;
      if (target?.id === 'wrapper') {
        const scrollYPosition = (event.target as Element).scrollTop;
        const scrollHeight = (event.target as Element).scrollHeight;
        if (!this.lock && !this.isLoading && scrollHeight * 0.9 < scrollYPosition + document.body.offsetHeight) {
          this.onScroll.emit();
        }
      }
    }, 400);
  }

}
